.navigation-bar {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: calc(var(--u-48) + var(--u-8));
	background: var(--col-accent-200);
	background: var(--col-white);
	background: var(--col-primary-600);
	border-top: 4px solid hsl(352, 76%, 70%);
	box-shadow: var(--shadow-h) var(--shadow-v) 8px rgba(0, 0, 0, 0.04);
}

.navigation-bar-content {
	width: 100%;
	max-width: var(--u-1280);
	display: flex;
	overflow-x: auto;
}

.navigation-bar a {
	height: 100%;
	display: flex;
	width: auto;
	align-items: center;
	padding: 0 var(--u-24);
	color: var(--col-primary-100);
	font-weight: bold;
	font-size: var(--fs-regular);
	transition: all 0.15s ease;
}

.navigation-bar a:hover {
	background: var(--col-accent-400);
	color: var(--col-accent-50);
}

.navigation-bar a.active {
	color: var(--col-accent-50);
	background: var(--col-accent-700);
}


.secondary-nav-bar {
	width: 100%;
	display: flex;
	justify-content: center;
	overflow-x: auto;
	height: calc(var(--u-32) + var(--u-8));
	background: var(--col-white);
	box-shadow: var(--shadow-h) var(--shadow-v) 8px rgba(0, 0, 0, 0.04);
}

.secondary-nav-bar-content {
	width: 100%;
	max-width: var(--u-1280);
	display: flex;
	align-items: center;
}

.secondary-nav-bar a {
	height: 100%;
	display: flex;
	width: auto;
	align-items: center;
	padding: 0 var(--u-24);
	/*text-transform: uppercase;*/
	font-weight: bold;
	font-size: var(--fs-small);
	transition: all 0.10s ease;
	padding-top: var(--u-2);
}

.secondary-nav-bar a:hover {
	color: var(--col-link-light);
}

.secondary-nav-bar a.active {
	color: var(--col-primary-600);
	background: var(--col-primary-100);
}
