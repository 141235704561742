.login {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: url('../../assets/competentiemonitor/header/header-background-login-blue.svg') repeat;
	background-size: 1920px auto;
}

.login .card,
.login .login-subcard {
	width: 100%;
	max-width: var(--u-512);
}

.login .card {
	box-shadow: calc(2 * var(--shadow-h)) calc(2 * var(--shadow-v)) 32px rgba(0, 0, 0, 0.2);
	margin-bottom: var(--u-8);
}

.login .login-subcard {
	padding: 8px 16px;
	text-align: center;
	color: var(--col-primary-200);
	font-size: 0.8em;
	line-height: 1.3em;
}

.login .login-subcard p {
	padding-bottom: 16px;
}

.login .login-subcard a {
	text-decoration: underline;
	color: var(--col-primary-100);
}
