.assessment-bar {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 48px;
	width: 100%;
	margin-top: 16px;
}

.assessment-bar:nth-of-type(1) {
	margin-top: 0;
}

.assessment-bar-name {
	width: 200px;
	padding: 0 16px 0 8px;
	display: flex;
	flex-direction: row-reverse;
	text-align: right;
	line-height: 1.2em;
}

.assessment-bar-progress {
	flex: 1;
	height: 100%;
	border-left: 1px solid var(--col-grey-300);
	padding: 2px;
}

.assessment-bar-block {
	height: 50%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.assessment-bar-grade {
	font-size: var(--fs-small);
	font-weight: bold;
	height: 100%;
	line-height: 100%;
	display: flex;
	align-items: center;
	padding-top: var(--u-2);
	padding-right: var(--u-4);
	/*color: var(--col-grey-300);*/
	justify-content: center;
	color: transparent;
}

.assessment-bar-block-enabled .assessment-bar-grade {
	color: white;
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
}

.assessment-bar-actions {
	width: var(--u-48);
}

@media screen and (max-width: 600px) {
	.assessment-bar-block {
		display: none;
	}

	.assessment-bar-block-highest {
		display: block;
		width: 100%;
		height: 32px;
		grid-column: 1 / span 6;
	}
}

.clickable-star {
	transform: scale(1);
	transition: 0.15s ease transform;
}

.clickable-star:hover {
	transform: scale(1.2);
}
