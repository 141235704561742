.tag {
	position: relative;
	height: var(--u-48);
	padding-left: var(--u-16);
	border-radius: 2px;
	background: var(--col-grey-100);
	margin-bottom: var(--u-8);
	margin-right: var(--u-8);
	display: flex;
	align-items: center;
}

.graph-overlay-wrapper {
	position: relative;
}

.graph-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.graph-overlay-message {
	background: var(--col-white);
	box-shadow: var(--shadow-h) var(--shadow-v) 8px rgba(0, 0, 0, 0.1);
	padding: var(--u-16) var(--u-24);
	max-width: var(--u-512);
	margin-bottom: var(--u-16);
}
