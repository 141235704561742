.multi-selector {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: var(--u-16);
	margin-bottom: var(--u-16);
}

.multi-selector-max-2-col {
	grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 1280px) {
	.multi-selector {
		grid-template-columns: 1fr 1fr;
	}
}

@media only screen and (max-width: 600px) {
	.multi-selector {
		grid-template-columns: 1fr;
	}
}

.multi-selector-option {
	display: block;
	background: var(--col-grey-100);
	border: 2px solid var(--col-grey-100);
	color: var(--col-grey-700);
	border-radius: 4px;
	padding: var(--u-16);
	padding-right: var(--u-32);
	font-size: var(--fs-small);
	line-height: 1.6em;
	position: relative;
	cursor: pointer;
	text-overflow: ellipsis;
	overflow: hidden;
}

.multi-selector-option .multi-name {
	white-space: nowrap;
}

.multi-selector-option:hover {
	border: 2px solid var(--col-grey-200);
	box-shadow: var(--shadow-h) var(--shadow-v) 8px -1px var(--col-primary-100);
}

.multi-selector-option .multi-name {
	font-size: var(--fs-regular);
	color: var(--col-grey-800);
	font-weight: bold;
}

.multi-selector-option.multi-selector-option-selected {
	background: var(--col-primary-100);
	color: var(--col-primary-700);
	border: 2px solid var(--col-primary-300);
}

.multi-selector-option.multi-selector-option-selected .multi-name {
	color: var(--col-primary-800);
}

.multi-selector-option-selected::after {
	font-family: 'FontAwesome';
	content: '\f058';
	font-size: 1.4em;
	position: absolute;
	top: var(--u-8);
	right: var(--u-12);
	color: var(--col-primary-500);
}

.multi-selector-disabled .multi-selector-option {
	cursor: not-allowed;
}

.multi-selector-disabled .multi-selector-option:hover {
	border-color: var(--col-grey-100);
}

.multi-selector-disabled .multi-selector-option-selected:hover {
	border-color: var(--col-primary-300);
}
