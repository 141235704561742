.table {
	width: 100%;
	border-collapse: collapse;
}

.table thead {
	color: var(--col-primary-50);
	font-size: var(--fs-small);
	/*text-transform: uppercase;*/
	text-align: left;
	background: var(--col-primary-500);
}

.table tr td,
.table tr th {
	height: var(--u-48);
	padding: 0 var(--u-8);
}

.table tbody tr:nth-of-type(2n + 1) td {
	background: var(--col-primary-50);
	color: var(--col-primary-800);
}

.table tbody tr:nth-of-type(2n) td {
	background: var(--col-primary-100);
	color: var(--col-primary-800);
}

.table tr td:nth-of-type(1),
.table tr th:nth-of-type(1) {
	padding-left: var(--u-24);
}

.table tr td:last-of-type,
.table tr th:last-of-type {
	padding-right: var(--u-24);
}

.table thead tr th {
	padding-top: var(--u-2);
	height: var(--u-32);
}

.table-padding {
	padding: var(--u-8) var(--u-24);
}

.pagination,
.pagination li {
	list-style: none;
}

.pagination {
	display: flex;
	justify-content: flex-end;
	padding: var(--u-16) var(--u-24);
}

.pagination .pagination-element {
	color: var(--col-grey-600);
}

.pagination a.pagination-element {
	color: var(--col-link);
}

.pagination a.pagination-element:hover {
	color: var(--col-link-light);
}

.pagination .pagination-element {
	user-select: none;
	display: flex;
	align-items: center;
	margin-left: var(--u-16);
	height: var(--u-48);
	/*background: var(--col-primary-100);*/
}

.pagination .active .pagination-element,
.pagination .active:hover .pagination-element {
	color: var(--col-grey-600);
}

.th-sortable {
	user-select: none;
}

.th-sortable-aligned-right {
	user-select: none;
	text-align: right;
}