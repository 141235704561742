.question {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-bottom: 32px;
}

.question-text-wrapper p {
	margin: 0;
	padding: 0;
}

.question:last-of-type {
	margin-bottom: 0;
}

.question-text-wrapper {
	flex: 1;
	padding-right: 32px;
}

@media only screen and (max-width: 600px) {
	.question {
		align-items: flex-start;
		flex-direction: column;
	}

	.question-text-wrapper {
		padding-right: 0;
	}

	.question-text {
		line-height: 1.5em;
	}

	.question-answer {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}

.yes-no-btn-group .btn {
	background: var(--col-grey-200);
	color: var(--col-grey-800);
	border: 0;
	width: 80px;
	border-left: 1px solid var(--col-grey-100);
	transition: box-shadow 0.2s ease;
}

.yes-no-btn-group .btn:hover {
	box-shadow: var(--shadow-h) var(--shadow-v) 8px inset rgba(0, 0, 0, 0.1);
}

.yes-no-btn-group .btn:nth-of-type(1) {
	border-left: 0;
}

.yes-no-btn-group .btn-yes.btn-active {
	background: var(--col-accent-500);
	color: var(--col-white);
	box-shadow: var(--shadow-h) var(--shadow-v) 8px inset rgba(0, 0, 0, 0.2);
}

.yes-no-btn-group .btn-no.btn-active {
	background: var(--col-accent-600);
	color: var(--col-white);
	box-shadow: var(--shadow-h) var(--shadow-v) 8px inset rgba(0, 0, 0, 0.2);
}

.assessment {
	display: flex;
	align-items: center;
	justify-content: center;
}

.assessment-compact {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 25px;
}

.assessment-form {
	display: flex;
	flex-direction: column;
	position: relative;
}

.assessment-level {
	display: grid;
	grid-template-columns: 1.55fr 1fr;
	align-self: stretch;
}

.comments {
	margin: var(--u-24) 0;
	padding-left: var(--u-24);

	position: relative;
	left: var(--u-8);
	padding-right: var(--u-8);
	overflow-y: auto;
}

.comments-content {
	height: 1px;
	overflow: visible;
}

.comment {
	background: var(--col-white);
	border-radius: 2px;
	margin-bottom: var(--u-16);
	box-shadow: var(--shadow-h) var(--shadow-v) 8px rgba(0, 0, 0, 0.1);
}

.comment-header {
	background: var(--col-primary-500);
	color: var(--col-primary-50);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: var(--u-32);
	align-items: center;
	padding: var(--u-2) var(--u-16) 0 var(--u-16);
	border-radius: 2px;
	font-weight: bold;
}

.comment-header .comment-header-created {
	font-size: var(--fs-small);
}

.comment-content {
	padding: var(--u-12) var(--u-16);
	color: var(--col-primary-800);
}

.comments textarea.form-element {
	background: var(--col-white);
	resize: none;
	height: var(--u-128);
	margin-bottom: var(--u-16);
}

.comments .button-area {
	padding-top: 0;
	padding-bottom: 0;
}

@media only screen and (max-width: 960px) {
	.assessment-level {
		grid-template-columns: 1fr;
	}

	.comments {
		margin: 0;
		left: 0;
		padding: 0 var(--u-16) var(--u-48) var(--u-16);
	}

	.comments-content {
		height: auto;
		overflow: auto;
	}
}

.block-with-terms {
	position: relative;
}

.term {
	/*position: relative;*/
	display: inline-flex;
	flex-direction: column;
	align-items: center;
}

.term-text {
	text-underline: var(--col-grey-200);
	text-decoration: underline;
}

.term .term-popup-sticky-wrapper {
	/* Should be bigger than .term-popup-sticky, but exact dimensions don't matter */
	width: 500px;
	margin: 0 -250px;
	display: flex;
	justify-content: center;
	z-index: 100;
}

.term .term-popup-sticky {
	position: sticky;
	left: 0;
	right: 0;
	width: calc(var(--u-256) + 10px);
	margin-left: calc(-0.5 * var(--u-256));
	margin-right: calc(-0.5 * var(--u-256));
	height: 0;
	z-index: 100;
}

.term .term-popup {
	display: none;
	width: var(--u-256);
	position: absolute;
	top: calc(100% + var(--u-8));
	left: 50%;
	margin-left: calc(var(--u-256) * -0.5);
	box-shadow: var(--shadow-h) var(--shadow-v) 16px rgba(0, 0, 0, 0.1);
	padding: var(--u-8) var(--u-16);
	z-index: 100;
	background: #fff;
	font-size: var(--fs-small);
	line-height: 1.5em;
	border-radius: var(--u-2);
	border: 1px solid var(--col-primary-500);

	background: var(--col-primary-500);
	color: var(--col-primary-50);
}

.term:hover .term-popup {
/*.term .term-popup {*/
	display: block;
}

/*.term .term-popup:after {*/
/*.term:hover .term-popup:after {*/
/*	content: '';*/
/*	position: absolute;*/
/*	width: 0;*/
/*	height: 0;*/
/*	border-left: 5px solid transparent;*/
/*	border-right: 5px solid transparent;*/
/*	border-bottom: 5px solid white;*/
/*	top: -5px;*/
/*	left: calc(50% - 2.5px);*/
/*}*/

.term-arrow-container {
	position: relative;
	z-index: 101;
}

.term:hover .term-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid var(--col-primary-500);
	top: 1px;
	left: calc(50% - 2.5px);
}

.term-text {
	text-underline: var(--col-grey-500);
}

.level-up {
	position: relative;
	overflow: hidden;
	height: 300px;
	width: 300px;
	font-size: 200px;
	margin-top: 35px;
}

.old-level {
	display: inline-block;
	text-align: center;
	line-height: 160px;
	position: absolute;
	top: calc((300px - 160px) / 2);
	margin-left: 3px;
	left: 0;
	right: 0;
	transform-origin: 0 100%;
	animation: flatten 1s cubic-bezier(0.92,-0.01, 1, 1);
	animation-fill-mode: both;
}

.new-level {
	display: inline-block;
	text-align: center;
	line-height: 300px;
	position: absolute;
	inset: 0;
	animation: slideIn 1s cubic-bezier(0.92,-0.01, 1, 1);
	animation-fill-mode: both;
}

.new-level:after {
	content: '';
	position: absolute;
	top: 0;
	left: -100px;
	transform: rotate(-45deg);
	background: linear-gradient(0deg, rgba(255, 255, 255, 0.0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.0) 100%);
	width: 500px;
	height: 20px;
	animation: glare 4s ease-in-out infinite;
}

@keyframes glare {
	from { transform: translate(-150px, -150px) rotate(-45deg); }
	to { transform: translate(250px, 250px) rotate(-45deg); }
}


@keyframes flatten {
	0% { transform: scaleY(1); opacity: 1; }
	40% { transform: scaleY(1); opacity: 1; }
	100% { transform: scaleY(0); opacity: 0; }
}

@keyframes slideIn {
	from { transform: translateY(-170px); opacity: 0; }
	to { transform: translateY(0); opacity: 1 }
}
