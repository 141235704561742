.app .header {
	/*background: #fff;*/
	background: url('../assets/competentiemonitor/header/header-background.svg') repeat;
	background-size: 1920px auto;
	box-shadow: var(--shadow-h) var(--shadow-v) 8px rgba(0, 0, 0, 0.04);
}

.app > .header .header-content {
	padding: var(--u-24) 0;
	/*display: grid;*/
	/*grid-template-columns: 1fr 1fr;*/
	/*grid-gap: var(--u-64);*/
	display: flex;
	justify-content: space-between;
}

.app > .header .header-content {
	max-width: var(--u-1280);
	margin: 0 auto;
}

.app > .header img.logo {
	width: 100%;
	max-width: 400px;
	height: auto;
}

.app > footer {
	max-width: var(--u-1280);
	width: 100%;
	margin: var(--u-48) auto var(--u-48) auto;
	font-size: var(--fs-small);
	color: var(--col-grey-700);
	padding: 0 var(--u-24);
}

.header-left {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding: 0 var(--u-16);
}

@media screen and (min-width: 1280px) {
	.header-left {
		padding: 0;
	}
}

.user-info {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	height: 100%;
	max-width: 100%;
	padding-left: var(--u-24);
	background: rgba(255, 255, 255, 0.3);
}

.user-info-icon {
	display: none;
}

.user-info-content {
	margin-right: var(--u-32);
}

.user-info-actions {
	margin-top: var(--u-16);
	display: none;
	font-size: var(--fs-small);
	/*text-transform: uppercase;*/
	font-weight: bold;
}

.user-info-name {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 300px;
	display: inline-block;
	font-weight: bold;
}

.user-info-school,
.user-info-schoolclass {
	color: var(--col-grey-700);
	/*text-transform: uppercase;*/
	font-size: var(--fs-small);
	font-weight: bold;
}

.user-info-secondary {
	line-height: 1.2em;
}

.user-info-icon-circle {
	border-radius: 50% 50%;
	width: calc(var(--u-48) + 1px);
	height: calc(var(--u-48) + 1px);
	line-height: calc(var(--u-48) + 1px);
	background: var(--col-primary-200);
	color: var(--col-primary-700);
	text-align: center;
	margin-right: var(--u-16);
	box-shadow: calc(-1 * var(--shadow-h)) calc(-1 * var(--shadow-v)) 4px rgba(255, 255, 255, 0.1);
	transition: box-shadow 0.15s ease, background 0.15s ease;
}

.user-info-icon-circle:hover {
	background: var(--col-primary-300);
}

.user-info-logout-icon-circle {
	border-radius: 50% 50%;
	width: calc(var(--u-48) + 1px);
	height: calc(var(--u-48) + 1px);
	line-height: calc(var(--u-48) + 1px);
	background: var(--col-grey-50);
	color: var(--col-grey-700);
	text-align: center;
	margin-right: var(--u-16);
	padding-top: var(--u-1);
	padding-left: var(--u-2);
	box-shadow: calc(-1 * var(--shadow-h)) calc(-1 * var(--shadow-v)) 4px rgba(255, 255, 255, 0.1);
	transition: all 0.15s ease;
}

.user-info-logout-icon-circle:hover {
	background: var(--col-red-200);
	color: var(--col-red-700);
}

.overlay-logout-icon-circle {
	border-radius: 10%/20%;
	width: calc(var(--u-48));
	height: calc(var(--u-48));
	line-height: calc(var(--u-48));
	background: var(--col-grey-200);
	color: var(--col-grey-700);
	text-align: center;
	box-shadow: calc(-1 * var(--shadow-h)) calc(-1 * var(--shadow-v)) 4px rgba(255, 255, 255, 0.1);
	transition: all 0.15s ease;
}

.overlay-logout-icon-circle:hover {
	background: var(--col-red-200);
	color: var(--col-red-700);
}

@media only screen and (max-width: 768px) {
	.user-info {
		background: none;
	}

	.user-info-content {
		display: none;
	}

	.user-info-icon {
		display: block;
	}

	.user-info-logout-icon {
		display: none;
	}

	.user-info-actions {
		display: block;
	}

	.user-info-visible-small .user-info-content {
		display: block;
		position: absolute;
		top: calc(100% + var(--u-2));
		right: calc(-1 * var(--u-16));
		/*width: var(--u-256);*/
		z-index: 10;
		background: #fff;
		box-shadow: var(--shadow-h) var(--shadow-v) 24px rgba(0, 0, 0, 0.1), var(--shadow-h) var(--shadow-v) 4px rgba(0, 0, 0, 0.15);
		padding: var(--u-16) var(--u-24);
		border-radius: 2px;
	}

	.user-info-visible-small .user-info-content:after {
		content: '';
		position: absolute;
		top: -5px;
		right: calc(var(--u-16) + 0.5 * 5px);
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid #fff;
	}

	.user-info-visible-small .user-info-icon .user-info-icon-circle {
		background: var(--col-primary-300);
		box-shadow: inset var(--shadow-h) var(--shadow-v) 4px rgba(0, 0, 0, 0.2);
	}
}

.app-content {
	max-width: var(--u-1280);
	margin: var(--u-48) auto var(--u-48) auto;
}

.app-overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.85);
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: space-between;
}

.app-overlay-top-bar {
	width: 100%;
	background: white;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0 var(--u-16);
	height: var(--u-48);
	box-shadow: var(--shadow-v) var(--shadow-h) 8px rgba(0, 0, 0, 0.3);
}

.app-overlay-top-bar h2 {
	/*text-transform: uppercase;*/
	font-weight: bold;
	font-size: var(--fs-regular);
	color: var(--col-grey-800);
	padding: 0;
	margin: 0;
}

.app-overlay-top-bar .right-side {
	display: flex;
	align-items: center;
}

.app-overlay-top-bar .progress {
	color: var(--col-grey-800);
	word-spacing: var(--u-4);
}

.app-overlay-top-bar .logout {
	padding-left: 25px;
}

.footer-logo {
	margin-top: var(--u-16);
	width: 200px;
	max-width: 200px;
	height: auto;
	max-height: 80px;
}
