.admin-overview {
	text-align: center;
}

.admin-overview-2-col {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: var(--u-32);
}

.admin-overview-3-col {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: var(--u-32);
}

@media only screen and (max-width: 600px) {
	.admin-overview-2-col {
		grid-template-columns: 1fr;
	}

	.admin-overview-3-col {
		grid-template-columns: 1fr;
	}
}

.big-fat-number {
	font-size: 48px;
	color: var(--col-grey-800);
	display: inline-block;
	padding: var(--u-16) 0;
}

.stat-description {
	font-size: var(--fs-small);
	/*text-transform: uppercase;*/
	font-weight: bold;
	color: var(--col-grey-600);
}
