.form-element,
.react-select .react-select__control {
	background: var(--col-grey-100);
	min-height: var(--u-48);
	height: var(--u-48);
	padding: var(--u-1) var(--u-8) 0 var(--u-8);
	line-height: var(--u-48);
	border-radius: var(--u-2);
	display: block;
	width: 100%;
	color: var(--col-grey-700);
	border: var(--u-1) solid var(--col-grey-200);
	font-size: var(--fs-regular);
	margin-bottom: var(--u-24);
	position: relative;
	overflow: hidden;
	transition: border 0.15s ease;
}

.react-select {
	margin-bottom: var(--u-24);
}

.react-select .react-select__control {
	margin-bottom: 0;
}

textarea.form-element {
	line-height: 1.5em;
}

.react-select__control {
	position: relative;
}

.react-select__control:after {
	content: "\f107";
	color: var(--col-grey-600);
	font-family: 'FontAwesome';
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: var(--u-48);
	transition: transform 0.2s ease;
	transform: rotateX(0deg);
}

.react-select__control--menu-is-open:after {
	transform: rotateX(180deg);
}

.react-select .react-select__value-container,
.react-select .react-select__value-container > * {
	min-height: 44px;
	height: 44px;
	padding: 0;
	margin: 0;
	line-height: 44px;
}

.react-select .react-select__value-container {
	padding: 0 8px;
}

.react-select .react-select__control {
	padding: 0;
}

.react-select {
	border: 0;
}

.react-select .react-select__control:hover {
	outline: none;
	/*border: 1px solid var(--col-primary-200);*/
	border: 1px solid var(--col-grey-200);
}

.react-select .react-select__option--is-focused {
	background: var(--col-light-grey);
}

.react-select .react-select__option--is-selected {
	background: var(--col-primary);
}

.form-element:focus,
.react-select .react-select__control--is-focused,
.react-select .react-select__control--is-focused:hover {
	outline: none !important;
	border: 1px solid var(--col-primary-500);
	box-shadow: none;
}

.validated-field-invalid .form-element:focus,
.validated-field-invalid .react-select .react-select__control--is-focused,
.validated-field-invalid .react-select .react-select__control--is-focused:hover {
	border: 1px solid var(--col-red-500);
}

.fixed-field {
	height: 48px;
	line-height: 48px;
	padding: 0 6px;
	white-space: nowrap;
	margin-bottom: 16px;
}

.label {
	color: var(--col-primary-500);
	font-size: var(--fs-small);
	/*text-transform: uppercase;*/
	font-weight: bold;
	padding-bottom: var(--u-4);
	padding-left: var(--u-4);
}

.btn {
	display: inline-block;
	background: var(--col-accent-500);
	/*text-transform: uppercase;*/
	color: white;
	border: 0;
	border-radius: var(--u-2);
	height: 48px;
	min-width: 48px;
	line-height: 48px;
	font-weight: bold;
	font-size: .85em;
	padding: 0 16px;
	transition: background 0.14s ease;
	margin: 0;
	user-select: none;
	white-space: nowrap;
	font-family: Lato, sans-serif;
}

.btn-primary {
	background: var(--col-primary-500);
}

.btn span {
	margin-left: 8px;
}

.btn span:nth-of-type(1) {
	margin-left: 0;
}

.btn:hover, .btn:active, .btn:focus  {
	outline: none;
	background: var(--col-accent-400);
	box-shadow: var(--shadow-h) var(--shadow-v) 4px rgba(0, 0, 0, 0.1);
	color: white;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
	background: var(--col-primary-400);
}

.btn:disabled {
	background: var(--col-grey-500);
	color: var(--col-grey-200);
	cursor: not-allowed;
}

.btn-danger:hover, .btn-danger:active, .btn-danger:focus {
	background: var(--col-danger);
}

@media only screen and (max-width: 600px) {
	.btn {
		height: 48px;
		line-height: 48px;
		padding: 0 16px;
	}

	.date-picker .btn, .time-picker .btn {
		height: 48px;
		line-height: 48px;
		padding: 0 12px;
	}
}

.table-buttons .btn {
	margin: -12px 2px;
}


.btn-group {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.btn-group .btn:nth-of-type(1) {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.btn-group .btn:last-of-type {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.btn-tertiary {
	color: var(--col-link);
	background: none;
	padding: 0;
	height: auto;
	min-height: auto;
	line-height: 1.5em;
	cursor: pointer;
}

.btn-tertiary:hover, .btn-tertiary:active, .btn-tertiary:focus {
	background: none;
	color: var(--col-link-light);
	box-shadow: none;
}

/* Validation on form fields */
.icon-field {
	position: relative;
}

.icon-field-content {
	width: 100%;
}

.icon-field-icon {
	position: absolute;
	top: 1px;
	right: 0;
	width: 48px;
	height: 48px;
	line-height: 48px;
	text-align: center;
	font-size: 1.2em;
	color: var(--col-dark-grey);
}

/* Date time stuff below */
.date-picker {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.date-picker > .fg-line {
	flex: 1;
	padding-right: 8px;
}

.date-picker-dropdown {
	position: absolute;
	font-size: 0.8em;
	padding: var(--u-8);
	top: 52px;
	width: var(--u-384);
	background: #fff;
	box-shadow: var(--shadow-h) var(--shadow-v) 8px -1px rgba(0, 0, 0, 0.2);
	z-index: 5;
	border-radius: 2px;
}

.date-picker-dropdown::after {
	content: '';
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
	position: absolute;
	top: -5px;
	left: 10px;
	box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.15);
}

.date-picker-dropdown::before {
	width: 0;
	height: 0;
	content: '';
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #fff;
	position: absolute;
	top: -7px;
	left: 8px;
}

.date-picker-dropdown .date-picker-header {
	display: grid;
	grid-template-columns: auto auto 1fr auto auto;
	grid-gap: var(--u-4);
}

.date-picker-dropdown .date-picker-header span {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	color: #333;
	text-transform: lowercase;
}

.date-picker-dropdown .btn {
	box-shadow: none;
}

.date-picker-dropdown table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 1px 1px;
	table-layout: fixed;
}

.date-picker-dropdown table td {
	text-align: center;
	padding: 2px 0;
	border-radius: 4px;
}

.date-picker-dropdown table thead td {
	font-weight: bold;
}

.date-picker-dropdown table tbody tr td:nth-of-type(6),
.date-picker-dropdown table tbody tr td:nth-of-type(7) {
	background: #efefef;
}

.date-picker-dropdown .greyed {
	color: #bbb;
}

.date-picker-dropdown table tbody tr td:hover {
	background: #ddd;
}

.date-picker-dropdown table tbody tr td.selected {
	background: var(--col-dark);
	color: white;
}

.date-picker-dropdown table tbody tr td.selected:hover {
	background: var(--col-primary);
}

.time-picker {
	position: relative;
	display: flex;
	flex-direction: row;
}

.time-picker > .btn {
	font-size: 1.2em;
}

.time-picker-dropdown {
	position: absolute;
	padding: 8px;
	top: 38px;
	width: 180px;
	background: #fff;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.4);
	z-index: 5;
	border-radius: 2px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	font-size: 2em;
	font-weight: bold;
}

.time-picker-dropdown::after {
	content: '';
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
	position: absolute;
	top: -5px;
	left: 10px;
	box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.15);
}

.time-picker-dropdown::before {
	width: 0;
	height: 0;
	content: '';
	border-left: 14px solid transparent;
	border-right: 14px solid transparent;
	border-bottom: 14px solid #fff;
	position: absolute;
	top: -7px;
	left: 1px;
}

.time-picker-dropdown .btn {
	box-shadow: none;
	transition: background 0.2s ease;
}

.time-picker-dropdown .btn:hover {
	background: #e5e5e5;
}

.time-picker-dropdown .time-picker-value {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.time-picker-dropdown .time-picker-colon {
	width: 6px;
	margin: 0 -10px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 4px;
}

.date-time-picker {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.date-time-picker .date-picker {
	width: 62%;
}

.date-time-picker .time-picker {
	width: 37%;
}

.date-picker-dropdown tr td {
	height: 48px;
}

input[disabled] {
	cursor: not-allowed;
	position: relative;
}

input[disabled] ::after {
	content: '<span class="fa fa-ban" />';
	position: absolute;
	top: 1px;
	right: 0;
	width: 48px;
	height: 48px;
}

blockquote {
	margin: 0;
	padding: 0;
	font-style: normal;
}

.collapsable-area {
	border: 2px solid var(--col-primary-500);
	border-radius: 4px;
	margin-bottom: var(--u-4);
}

.collapsable-area-header {
	padding: var(--u-4) var(--u-24);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background: var(--col-primary-100);
}

.collapsable-area-header .collapsable-area-label {
	color: var(--col-primary-800);
	/*text-transform: uppercase;*/
	font-weight: bold;
}

.collapsable-area-content {
	border-top: 2px solid var(--col-primary-500);
	padding: var(--u-16) var(--u-24);
}

.faq-collapsable-area {
	border: 1px solid;
	border-radius: 3px;
	margin-bottom: var(--u-4);

}

.faq-collapsable-area-header-open {
	padding: var(--u-4) var(--u-24);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background: var(--col-primary-500);
	cursor: pointer;
}

.faq-collapsable-area-header-closed {
	padding: var(--u-4) var(--u-24);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background: var(--col-primary-100);
	cursor: pointer;
}

.faq-collapsable-area-header .collapsable-area-label {
	color: var(--col-primary-800);
}

.faq-collapsable-area-content {
	border-top: 2px solid var(--col-primary-500);
	padding: var(--u-16) var(--u-24);
}

.faq-collapsable-area-text-closed {
	font-size: var(--fs-small);
	color: var(--col-primary-800);
}

.faq-collapsable-area-text-open {
	font-size: var(--fs-small);
	color: var(--col-primary-100);
}

.faq-collapsable-area-text-answer {
	font-size: var(--fs-regular);
	color: var(--col-primary-800);
	font-weight: normal;
}

.button-area {
	padding: var(--u-16) 0 var(--u-8) 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.button-area .btn-tertiary {
	margin-right: var(--u-48);
}

.error-message {
	padding: var(--u-12) var(--u-8);
	background: var(--col-red-200);
	color: var(--col-red-700);
	margin-bottom: var(--u-32);
	border-radius: var(--u-2);
	display: flex;
	flex-direction: row;
}

.error-message-icon {
	width: var(--u-24);
	text-align: center;
	margin-right: var(--u-8);
	padding-top: var(--u-2);
}

.error-message-content {
	flex: 1;
	padding-top: var(--u-2);
}

.small-text {
	font-size: var(--fs-small);
	color: var(--col-grey-700);
	padding: 0;
	margin: 0 0 var(--u-8) 0;
	line-height: 1em;
}

/* Form layout stuff */
.form-row:nth-of-type(1) {
	margin-top: 0;
	padding-top: 0;
	border-top: 0;
}

.form-row {
	display: grid;
	grid-template-columns: 1fr 1.618fr;
	grid-gap: var(--u-24);
	margin-top: var(--u-24);
	padding-top: var(--u-24);
	border-top: 1px solid var(--col-grey-200);
}

.form-row .form-row-info p {
	font-size: var(--fs-small);
	color: var(--col-grey-700);
	padding: 0;
	margin: var(--u-4) 0 var(--u-8) 0;
	line-height: 1.5em;
}

.form-row-content {
	min-width: 0;
	/*background: var(--col-grey-100);*/
	padding: var(--u-12);
}

@media only screen and (max-width: 600px) {
	.form-row {
		grid-template-columns: 1fr;
		margin-top: var(--u-32);
		padding-top: var(--u-32);
		grid-gap: var(--u-16);
	}

	.form-row-content {
		min-width: 0;
		background: var(--col-grey-100);
		padding: var(--u-12);
	}
}

.form-2-col {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: var(--u-48);
}

@media only screen and (max-width: 600px) {
	.form-2-col {
		grid-template-columns: 1fr;
		grid-column-gap: 0;
	}
}

.color-input-field {
	display: grid;
	grid-template-columns: auto 1fr;
	flex-direction: row;
}

.color-input-field-example {
	height: var(--u-48);
	width: var(--u-64);
	border-radius: 2px;
	margin-right: var(--u-8);
}

.tox-statusbar__branding {
	display: none !important;
}

.html-field  {
	margin-bottom: var(--u-24);
}

.form-explanation {
	font-size: var(--fs-small);
	color: var(--col-grey-600);
	padding-left: var(--u-8);
	line-height: 1.5em;
	margin-top: calc(-1 * var(--u-12));
	padding-bottom: var(--u-16);
}

hr {
	border: none;
	border-bottom: 1px solid var(--col-grey-300);
	margin-bottom: var(--u-24);
}

.ReactModal__Overlay {
	opacity: 0;
	transition: opacity 0.15s ease-in-out;
}

.ReactModal__Overlay--after-open{
	opacity: 1;
}

.ReactModal__Overlay--before-close{
	opacity: 0;
}
