.page-overview-card-content {
	max-height: var(--u-256);
	overflow: hidden;
	position: relative;
}

.page-overview-card-content::after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: var(--u-64);
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}
