.taxonomy-description {
	position: relative;
	flex: 1;
	overflow: hidden;
	padding: 0 var(--u-24);
}

.taxonomy-description::after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: var(--u-48);
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}
