.static-page-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: var(--u-16);
}

.static-page {
	width: 100%;
	max-width: var(--u-768);
}

.static-page-footer {
	border-top: 1px solid var(--col-grey-300);
	width: 100%;
	max-width: var(--u-768);
	margin-top: var(--u-24);
	padding: var(--u-16);
	display: flex;
	flex-direction: row;
}

.static-page-footer-content {
	padding: 0 var(--u-24);
}

.static-page-footer-logo img {
	width: var(--u-64);
	height: auto;
}
