.tab-bar-large {
	display: flex;
	flex-direction: row;
}

.tab {
	height: 48px;
	line-height: 48px;
	padding: 0 12px;
	border-top: 1px solid transparent;
	border-right: 1px solid var(--col-primary-200);
	border-bottom: 1px solid var(--col-primary-200);
	background: var(--col-primary-50);
	cursor: pointer;
	color: var(--col-primary-700);
	user-select: none;
}

.tab.tab-remaining {
	flex: 1;
	border-right: none;
}

.tab.tab-selected {
	background: #fff;
	border-bottom: none;
	padding-top: -1px;
	padding-bottom: 1px;
	border-top: 1px solid var(--col-primary-200);
}

.tab-bar-small {
	display: none;
}

.tab-bar-small > select {
	height: 48px;
	width: 100%;
	font-size: 1em;
	padding: 0 16px;
	background: #fff;
	border: 0;
	border-bottom: 1px solid #ccc;
}

@media only screen and (max-width: 1000px) {
	.tab-bar-large {
		display: none;
	}

	.tab-bar-small {
		display: flex;
	}
}
