@import url('../assets/fonts/roboto-roboto-slab.css');

@import url('./frame.css');
@import url('./navigation.css');
@import url('./tables.css');
@import url('./links.css');
@import url('./forms.css');
@import url('./tabs.css');

@import url('./components/multi-selector.css');

@import url('./page-specific/assessmentbar.css');
@import url('./page-specific/assessmentform.css');
@import url('./page-specific/login.css');
@import url('./page-specific/static-page.css');
@import url('./page-specific/pages-overview.css');
@import url('./page-specific/invite-students.css');
@import url('./page-specific/admin-overview.css');
@import url('./page-specific/terms-overview.css');
@import url('./page-specific/growth-graph.css');


:root {
	/* Legacy colors */
	--col-dark: #2d3142;
	--col-light-grey-slightly-lighter: #d2e9f2;
	--col-light-grey: #c0d6df;
	--col-light-grey-slightly-darker: #BBC0C1;
	--col-dark-grey: #4F6D7A;
	--col-dark-grey-slightly-darker: #364b54;
	--col-accent: #5bbf6c;
	--col-danger: #bf5b5b;
	--col-primary: #3393C6;

	--col-green: #5bbf6c;
	--col-red: #bf5b5b;


	/* Technasium branding colors */
	--col-grey-900: #262228;
	--col-grey-800: #3a373c;
	--col-grey-700: #4c464e;
	--col-grey-600: #625c64;
	--col-grey-500: #7f7782;
	--col-grey-400: #908a92;
	--col-grey-300: #afaab2;
	--col-grey-200: #d8d0da;
	--col-grey-100: #edeeef;
	--col-grey-50: #f7f7f9;

	--col-white: #fff;

	--col-primary-900: hsl(196, 100%, 10%);
	--col-primary-800: hsl(196, 100%, 15%);
	--col-primary-700: hsl(196, 100%, 23%);
	--col-primary-600: hsl(196, 95%, 29%);
	--col-primary-500: hsl(196, 80%, 35%);
	--col-primary-400: hsl(196, 60%, 40%);
	--col-primary-300: hsl(196, 60%, 70%);
	--col-primary-200: hsl(196, 60%, 85%);
	--col-primary-100: hsl(196, 100%, 93%);
	--col-primary-50: hsl(196, 100%, 97%);

	--col-accent-900: var(--col-primary-900);
	--col-accent-800: var(--col-primary-800);
	--col-accent-700: var(--col-primary-700);
	--col-accent-600: var(--col-primary-600);
	--col-accent-500: var(--col-primary-500);
	--col-accent-400: var(--col-primary-400);
	--col-accent-300: var(--col-primary-300);
	--col-accent-200: var(--col-primary-200);
	--col-accent-100: var(--col-primary-100);
	--col-accent-50: var(--col-primary-50);

	--col-accent-desat-900: var(--col-primary-900);
	--col-accent-desat-800: var(--col-primary-800);
	--col-accent-desat-700: var(--col-primary-700);
	--col-accent-desat-600: var(--col-primary-600);
	--col-accent-desat-500: var(--col-primary-500);
	--col-accent-desat-400: var(--col-primary-400);
	--col-accent-desat-300: var(--col-primary-300);
	--col-accent-desat-200: var(--col-primary-200);
	--col-accent-desat-100: var(--col-primary-100);
	--col-accent-desat-50: var(--col-primary-50);

	--col-red-900: #450B00;
	--col-red-800: #681100;
	--col-red-700: #8A1700;
	--col-red-600: #CF2200;
	--col-red-500: #E62600;
	--col-red-400: #EE674D;
	--col-red-300: #F5A899;
	--col-red-200: #F9C9BF;
	--col-red-100: #FDE9E6;
	--col-red-50: #FEF4F2;

	--col-link: var(--col-primary-500);
	--col-link-light: var(--col-primary-400);

	/* Make sure shadow is always equal in direction */
	--shadow-h: 1px;
	--shadow-v: 2px;

	/* Fixed font sizes */
	--fs-tiny: 10px;
	--fs-small: 15px;
	--fs-regular: 18px;
	--fs-bigger: 24px;
	--fs-biggest: 30px;
	--fs-super-turbo-turbo-big: 72px;

	/* Fixed units */
	--u-1: 1px;
	--u-2: 2px;
	--u-4: 4px;
	--u-6: 6px;
	--u-8: 8px;
	--u-12: 12px;
	--u-16: 16px;
	--u-24: 24px;
	--u-32: 32px;
	--u-48: 48px;
	--u-64: 64px;
	--u-96: 96px;
	--u-128: 128px;
	--u-192: 192px;
	--u-256: 256px;
	--u-384: 384px;
	--u-512: 512px;
	--u-768: 768px;
	--u-960: 960px;
	--u-1280: 1280px;
	--u-1600: 1600px;
	--u-2400: 2400px;
}

html, body {
	margin: 0;
	padding: 0;
	font-family: Roboto, sans-serif;
	background: var(--col-grey-100);
	min-width: var(--u-256);
	font-size: var(--fs-regular);
	line-height: 1.6em;
	color: var(--col-grey-900);
}

@media screen and (max-width: 600px) {
	html, body {
		font-size: 16px;
	}
}

html, body, #root {
	height: 100%;
}

#root {
	overflow: auto;
}

* {
	box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: var(--u-8) 0 var(--u-32) 0;
}

ol li, ul li {
	padding-left: var(--u-8);
	padding-bottom: var(--u-8);
}

p {
	margin: var(--u-8) 0;
}

hr {
	border: 0;
	border-top: 1px solid var(--col-grey-300);
	margin: var(--u-32) 0;
	background: none;
}

.topbar {
	height: var(--u-48);
	line-height: var(--u-48);
	background: var(--col-primary-500);
	box-shadow: 0 0 var(--u-8) rgba(0, 0, 0, 0.3);
	color: white;
	font-weight: bold;
	padding-right: var(--u-24);
	z-index: 10000;
}

.card {
	background: var(--col-white);
	box-shadow: var(--shadow-h) var(--shadow-v) var(--u-16) -12px rgba(0, 0, 0, 0.2);
	position: relative;
	border-radius: var(--u-2);
	margin: var(--u-24) 0;
	min-height: var(--u-96);
}

.card-accent {
	position: absolute;
	top: 0;
	left: 0;
	width: 8px;
	bottom: 0;
}

.card-padding {
	padding: var(--u-24) var(--u-32);
}

.header-a {
	display: inline-block;
	padding: 0;
	margin: 0;
	color: var(--col-grey-800);
	/*text-transform: uppercase;*/
	font-weight: bold;
	font-size: var(--fs-regular);
}

.header-b {
	display: inline-block;
	padding-bottom: 10px;
	margin: 0;
	color: var(--col-grey-800);
	font-weight: bold;
	font-size: var(--fs-regular);
}

.text-small {
	font-size: var(--fs-small);
	color: var(--col-grey-700);
}

.card-toolbar {
	height: var(--u-48);
	line-height: var(--u-48);
	padding-left: var(--u-24);
}

.card-toolbar h2 {
	display: inline-block;
	padding: 0;
	margin: 0;
	color: var(--col-grey-800);
	/*text-transform: uppercase;*/
	font-weight: bold;
	font-size: var(--fs-regular);
}

.card-toolbar-right {
	float: right;
	padding-right: var(--u-16);
}

.card-toolbar-btn {
	position: relative;
	z-index: 1;
	display: inline-block;
	width: var(--u-32);
	height: var(--u-32);
	line-height: var(--u-32);
	text-align: center;
}

.card-toolbar-btn:hover {
	color: var(--col-primary-500);
}

.card-loading-spinner-min-height-spacer {
	height: var(--u-96);
	position: absolute;
	top: 0;
}

.card-loading-spinner {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner-img {
	animation: spin 0.8s linear infinite;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

@keyframes zoomOpacityIn {
	from { opacity: 0; transform: scale(0.2); }
	to { opacity: 0.15; transform: scale(1); }
}

.card-toolbar-btn:hover::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: var(--u-32);
	height: var(--u-32);
	border-radius: 50%;
	z-index: 0;
	opacity: 0.15;
	background: var(--col-dark);
	animation: zoomOpacityIn 0.2s ease;
}

.tooltip {
	position: absolute;
	bottom: 20px;
	left: 15%;
	background: #333;
	margin-left: calc(-0.5 * var(--u-256));
	width: var(--u-256);
	z-index: 100;
	text-align: center;
	color: white;
	font-weight: normal;
	font-size: 0.8em;
	padding: 8px;
	text-transform: initial;
	border-radius: 2px;
}

.tooltip::after {
	content: '';
	width: 0;
	height: 0;
	position: absolute;
	bottom: -5px;
	left: 50%;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #333;
}

@media only screen and (max-width: 600px) {
	.card {
		margin: var(--u-32) 0;
	}

	.card-padding {
		padding-left: 16px;
		padding-right: 16px;
	}

	.hide-mobile.hide-mobile {
		display: none;
	}
}

.content {
	padding-top: 64px;
}

.content-pad-sidebar {
	padding-left: 300px;
}

.app-bar {
	background: var(--col-primary);
	height: var(--u-48);
	line-height: var(--u-48);
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
	padding-top: 2px;
	padding-left: var(--u-64);
	color: var(--col-light-grey-slightly-lighter);
	font-weight: bold;
	font-size: var(--fs-regular);
	/*text-transform: uppercase;*/
}

.green {
	color: var(--col-green);
}

.red {
	color: var(--col-red);
}

.well {
	background: var(--col-grey-100);
	padding: var(--u-16) var(--u-24);
	margin-bottom: var(--u-16);
}

.rsp-mobile-only {
	display: none;
}

@media screen and (max-width: 600px) {
	.rsp-no-mobile {
		display: none;
	}

	.rsp-mobile-only {
		display: block;
	}
}

.grid-2-1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: var(--u-16) var(--u-32) ;
}

@media screen and (max-width: 600px) {
	.grid-2-1 {
		grid-template-columns: 1fr;
		gap: 0;
	}
}
